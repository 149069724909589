import React from "react";
import Swiper from "react-id-swiper";
import "swiper/swiper.min.css";
import infrastructure1 from "../assets/images/ArtRoom.png";
import infrastructure2 from "../assets/images/AVRoom.png";
import infrastructure3 from "../assets/images/Badminton.png";
import infrastructure4 from "../assets/images/Campus.png";
import infrastructure5 from "../assets/images/ClassRoom.png";
import infrastructure6 from "../assets/images/ConferenceRoom.png";
import infrastructure7 from "../assets/images/DanceRoom.png";
import infrastructure8 from "../assets/images/KarateCourt.png";
import infrastructure9 from "../assets/images/ComputerLab.png";
import infrastructure10 from "../assets/images/BiologyLab.png";
import infrastructure11 from "../assets/images/ChemistryLab.png";
import infrastructure12 from "../assets/images/PhysicsLab.png";
import infrastructure13 from "../assets/images/Library.png";   
import infrastructure14 from "../assets/images/TennisCourt.png";
import infrastructure15 from "../assets/images/StaffRoom.png";
import infrastructure16 from "../assets/images/SportsRoom.png";
import infrastructure17 from "../assets/images/YogaRoom.png";
import infrastructure18 from "../assets/images/SchoolBus.png";


const InfraStructure = () => {
  const params = {
    slidesPerView: 3,
    loop: false,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },

    // Responsive breakpoints
    breakpoints: {
      1024: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 2
      },
      640: {
        slidesPerView: 2
      },
      320: {
        slidesPerView: 1
      }
    }
  };

  return (
        <section className="testimonials-one testimonials-one__home-three">
          <div className="container">
            <div className="testimonials-one__carousel">
            <Swiper {...params}>
            <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={infrastructure1} alt="" height={550} /> 
                <div className="course-one__contents">
                    <a href="#none" className="course-one__category">
                      Art Room
                    </a>
                  </div>
              </div>  
          </div>
          <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={infrastructure2} alt="" height={550} /> 
                <div className="course-one__contents">
                    <a href="#none" className="course-one__category">
                      Audio & Visual Room
                    </a>
                  </div>
              </div>  
          </div>
          <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={infrastructure3} alt="" height={550} /> 
                <div className="course-one__contents">
                    <a href="#none" className="course-one__category">
                      Badminton Court
                    </a>
                  </div>
              </div>  
          </div>
          <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={infrastructure4} alt="" height={550} /> 
                <div className="course-one__contents">
                    <a href="#none" className="course-one__category">
                      Campus
                    </a>
                  </div>
              </div>  
          </div>
          <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={infrastructure5} alt="" height={550} /> 
                <div className="course-one__contents">
                    <a href="#none" className="course-one__category">
                      CLass Room
                    </a>
                  </div>
              </div>  
          </div>
          <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={infrastructure6} alt="" height={550} /> 
                <div className="course-one__contents">
                    <a href="#none" className="course-one__category">
                      Conference Room
                    </a>
                  </div>
              </div>  
          </div>
          <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={infrastructure7} alt="" height={550} /> 
                <div className="course-one__contents">
                    <a href="#none" className="course-one__category">
                      Dance Room
                    </a>
                  </div>
              </div>  
          </div>
          <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={infrastructure8} alt="" height={550} /> 
                <div className="course-one__contents">
                    <a href="#none" className="course-one__category">
                      Karate Court
                    </a>
                  </div>
              </div>  
          </div>
          <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={infrastructure9} alt="" height={550} /> 
                <div className="course-one__contents">
                    <a href="#none" className="course-one__category">
                      Computer Lab
                    </a>
                  </div>
              </div>  
          </div>
          <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={infrastructure10} alt="" height={550} /> 
                <div className="course-one__contents">
                    <a href="#none" className="course-one__category">
                      Biology Lab
                    </a>
                  </div>
              </div>  
          </div>
          <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={infrastructure11} alt="" height={550} /> 
                <div className="course-one__contents">
                    <a href="#none" className="course-one__category">
                      Chemistry Lab
                    </a>
                  </div>
              </div>  
          </div>
          <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={infrastructure12} alt="" height={550} /> 
                <div className="course-one__contents">
                    <a href="#none" className="course-one__category">
                      Physics Lab
                    </a>
                  </div>
              </div>  
          </div>
          <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={infrastructure13} alt="" height={550} /> 
                <div className="course-one__contents">
                    <a href="#none" className="course-one__category">
                    Library
                    </a>
                  </div>
              </div>  
          </div>
          <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={infrastructure14} alt="" height={550} /> 
                <div className="course-one__contents">
                    <a href="#none" className="course-one__category">
                    Tennis Court
                    </a>
                  </div>
              </div>  
          </div>
          <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={infrastructure15} alt="" height={550} /> 
                <div className="course-one__contents">
                    <a href="#none" className="course-one__category">
                    Staff Room
                    </a>
                  </div>
              </div>  
          </div>

          <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={infrastructure16} alt="" height={550} /> 
                <div className="course-one__contents">
                    <a href="#none" className="course-one__category">
                    Sports Room
                    </a>
                  </div>
              </div>  
          </div>

          <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={infrastructure17} alt="" height={550} /> 
                <div className="course-one__contents">
                    <a href="#none" className="course-one__category">
                    Yoga Room
                    </a>
                  </div>
              </div>  
          </div>

          <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={infrastructure18} alt="" height={550} /> 
                <div className="course-one__contents">
                    <a href="#none" className="course-one__category">
                    School Bus
                    </a>
                  </div>
              </div>  
          </div>


              
              </Swiper>
            </div>
            
          </div>
        </section>
      );
};
export default InfraStructure;
